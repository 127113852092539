import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../i18n';

const About = () => {
    const {t} =useTranslation();

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/contact-us');
    };

    return (
        <div dir={i18n.dir()} className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <h1 className="text-6xl text-blue-950 mb-10 mt-12 font-semibold">{t('about.title')}</h1>

            <div className="flex flex-col md:flex-row items-center justify-between w-4/5 mb-10">
                <div className="w-full md:w-1/2">
                    <img
                        src="/image9.jpg"
                        alt="about"
                        className="w-[90%] px-2 h-auto object-cover transition-transform duration-500 hover:scale-105"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pl-8 mb-24 md:mt-0 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-5xl font-bold text-blue-950 text-center mt-6 mb-4">{t('about.whoWeAre.title')}</h2>
                    <p className="text-3xl text-gray-700">
                        {t('about.whoWeAre.description')}
                    </p>
                </div>
            </div>


            <div className="flex flex-col md:flex-row items-center justify-between w-4/5 mb-10 mt-10">
                <div className="w-full md:w-1/2 md:pr-8 mb-24 md:mt-0 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-5xl font-bold text-blue-950 text-center mt-6 mb-4">  {t('about.ourVision.title')}</h2>
                    <p className="text-3xl text-gray-700">
                        {t('about.ourVision.description')}
                    </p>
                </div>
                <div className="w-full md:w-1/2">
                    <img
                        src="/vision.jpg"
                        alt="mission"
                        className="w-[90%] px-2 h-auto object-cover transition-transform duration-500 hover:scale-105"
                    />
                </div>
            </div>


            <div className="flex flex-col md:flex-row items-center justify-between w-4/5 mb-10 mt-10">
                <div className="w-full md:w-1/2">
                    <img
                        src="/mission.webp"
                        alt="about"
                        className="w-[90%] px-2 h-auto object-cover rounded-lg transition-transform duration-500 hover:scale-105"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pl-8 mb-24 md:mt-0 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-5xl font-bold text-blue-950 mt-10 text-center mb-4">{t('about.ourMission.title')}</h2>
                    <p className="text-3xl text-gray-700">
                        {t('about.ourMission.description')}
                    </p>
                </div>
            </div>


            <div className="flex flex-col items-center justify-center w-4/5 mb-32">
                <h2 className="text-5xl font-bold text-blue-950 text-center mt-6 mb-8">{t('about.ourValues.title')}</h2>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-full">
                    {t('about.ourValues.values', { returnObjects: true }).map((value, index) => (
                        <div
                            key={index}
                            className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg transition-transform duration-500 hover:scale-105"
                        >
                            <h3 className="text-2xl font-semibold text-blue-950 mb-4">{value.title}</h3>
                            <p className="text-gray-700 text-center">{value.description}</p>
                        </div>
                    ))}
                </div>
            </div>


            <div className="w-full flex flex-col items-center justify-center p-12 mb-20 bg-gray-200 text-center">
                <h2 className="text-3xl font-semibold mb-4">{t('about.howCanWeHelpYou')}</h2>
                <p className="text-lg mb-6">
                    {t('about.readyToPushBoundaries')}
                </p>
                <button onClick={handleButtonClick}
                        className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition">
                    {t('about.callToAction.letsWorkTogether')}
                </button>
            </div>


        </div>
    );
};

export default About;
