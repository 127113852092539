const CustomInput = ({ label, name, value, onChange, placeholder, isCompulsory, type = "text", isTextArea = false }) => {
    return (
        <div className="mb-4">
            <label className="block text-sm font-medium mb-2">
                {label} {isCompulsory && <span className="text-red-500">*</span>}
            </label>
            {isTextArea ? (
                <textarea
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                    className="w-full p-2 border border-gray-300 rounded"
                    required={isCompulsory}
                />
            ) : (
                <input
                    type={type}
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder={placeholder}
                    className="w-full p-2 border border-gray-300 rounded"
                    required={isCompulsory}
                />
            )}
        </div>
    );
};

export default CustomInput;
