import { BrowserRouter as Router, Route, Routes, NavLink } from "react-router-dom";
import Home from "./components/Home";
import Services from "./components/Services";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Portfolio from "./components/Portfolio";
import FAQs from "./components/FAQs";
import './App.css';
import ScrollToTop from "./components/ScrollToTop";
import LanguageSelector from "./LanguageSelector";
import "./i18n";
import { useTranslation } from 'react-i18next';
import i18n from "./i18n";


function App() {

    const { t } = useTranslation();
    return (
        <Router>
            <div dir={i18n.dir()} className="flex flex-col min-h-screen">
                <header className="sticky top-0 bg-gray-100 p-4 z-50 shadow-lg">
                    <nav className="container mx-auto flex items-center h-12">
                        <NavLink
                            to="/"
                        >
                            <img src="/logo.png" alt="Logo" className="h-32 max-h-full w-auto object-contain"/>
                        </NavLink>
                        <div className="flex-grow flex justify-center gap-14 ">
                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    isActive
                                        ? "text-orange-500 transition duration-300"
                                        : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                }
                            >
                                {t('app.home')}
                            </NavLink>
                            <NavLink
                                to="/services"
                                className={({ isActive }) =>
                                    isActive
                                        ? "text-orange-500 transition duration-300"
                                        : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                }
                            >

                                {t('app.services')}
                            </NavLink>
                            <NavLink
                                to="/portfolio"
                                className={({ isActive }) =>
                                    isActive
                                        ? "text-orange-500 transition duration-300"
                                        : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                }
                            >
                                {t('app.portfolio')}
                            </NavLink>
                            <NavLink
                                to="/faqs"
                                className={({ isActive }) =>
                                    isActive
                                        ? "text-orange-500 transition duration-300"
                                        : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                }
                            >
                                {t('app.faqs')}
                            </NavLink>
                            <NavLink
                                to="/about"
                                className={({ isActive }) =>
                                    isActive
                                        ? "text-orange-500 transition duration-300"
                                        : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                }
                            >
                                {t('app.about')}
                            </NavLink>
                            <NavLink
                                to="/contact-us"
                                className={({ isActive }) =>
                                    isActive
                                        ? "text-orange-500 transition duration-300"
                                        : "text-[#1E314B] hover:text-orange-500 transition duration-300"
                                }
                            >
                                {t('app.contactUs')}
                            </NavLink>

                        </div>
                        <LanguageSelector/>

                    </nav>

                </header>

                <ScrollToTop />

                <main className="flex-grow">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/portfolio" element={<Portfolio />} />
                        <Route path="/faqs" element={<FAQs />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact-us" element={<ContactUs />} />
                    </Routes>
                </main>

                <Footer />
            </div>
        </Router>
    );
}

export default App;
