import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';



const Services = () => {
    const { t } = useTranslation();
    const isArabic = i18n.language === 'ar';
    const navigate = useNavigate();


    const handleButtonClick = () => {
        navigate('/contact-us');
    };

    return (
        <div dir={i18n.dir()} className="min-h-screen flex flex-col items-center  justify-center bg-gray-100">

            <div className="relative w-full mb-0">
                <img
                    src="/image6.avif"
                    alt="Full width background"
                    className="w-full h-auto object-cover animate-fade-in-up"
                />
                <div className="absolute mt-44 flex-col w-full inset-y-0 p-20 left-0 flex ">
                    <h1 className={`${isArabic ? 'text-black' : 'text-white'} text-6xl text-left font-bold`}>
                        {t('services.title')}
                    </h1>

                    <ol className={`${isArabic ? 'text-black' : 'text-white'} text-4xl text-left mt-8 font-bold`}>
                        <li className="mt-4 animate-from-left">{t('services.digitalMarketing.title')}</li>
                        <li className="mt-2 animate-from-right">{t('services.webDevelopment.title')}</li>
                        <li className="mt-2 animate-from-left">{t('services.appDesign.title')}</li>
                        <li className="mt-2 animate-from-right">{t('services.advertising.title')}</li>
                        <li className="mt-2 animate-from-left">{t('services.graphicDesign.title')}</li>
                        <li className="mt-2 animate-from-right">{t('services.consultation.title')}</li>
                    </ol>
                </div>
            </div>


            <div
                className="flex flex-col mt-32 md:flex-row w-full h-[70%] p-2 bg-gray-100 shadow-lg rounded-lg">


            <div className="w-full md:w-1/2 p-4 transition-transform duration-500  hover:scale-95 ">
                    <h2 className="text-6xl text-blue-950 font-semibold text-left pl-8 mt-32">{t('services.digitalMarketing.title')}</h2>
                    <p className="text-3xl mt-12 text-gray-700 pl-8">
                        {t('services.digitalMarketing.description')}
                    </p>

                </div>


                <div className="w-full md:w-1/2 p-4 flex items-center justify-center">
                    <img
                        src="/image3.webp"
                        alt="Service representation"
                        className="w-[60%] h-auto object-cover rounded-lg transition-transform duration-500 hover:scale-95"
                    />
                </div>
            </div>


            <div className="flex flex-col md:flex-row w-full h-[70%] p-2 bg-gray-100 shadow-lg rounded-lg  ">
                <div className="w-full md:w-1/2 p-4 flex items-center justify-center">
                    <img
                        src="/image15.webp"
                        alt="New Service Representation"
                        className="w-[60%] h-auto object-cover rounded-lg transition-transform duration-500 hover:scale-95"
                    />
                </div>

                <div className="w-full md:w-1/2 p-4 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-6xl text-blue-950 font-semibold mt-32">{t('services.webDevelopment.title')}</h2>
                    <p className="text-3xl mt-4 text-gray-700">
                        {t('services.webDevelopment.description')}

                    </p>
                </div>
            </div>


            <div className="flex flex-col md:flex-row w-full  mt-28 max-w-8xl p-12 bg-[#1E314B] shadow-lg rounded-lg">

                <div className="flex flex-col items-center justify-center w-full md:w-1/3 p-4">
                    <div
                        className="bg-white shadow-md rounded-lg p-2 flex flex-col items-center h-96 transition-transform duration-500 hover:scale-95">
                        <img
                            src="/service3.jpg"
                            alt="Card 1"
                            className="w-full h-60 rounded-md mb-4"
                        />
                        <h3 className="text-xl font-semibold text-blue-950">{t('services.appDesign.title')}</h3>
                        <p className="text-gray-700 text-center">{t('services.appDesign.description')}
                        </p>
                    </div>
                </div>


                <div className="flex flex-col items-center justify-center w-full md:w-1/3 p-4">
                    <div
                        className="bg-white shadow-md rounded-lg p-2 flex flex-col items-center h-96 transition-transform duration-500 hover:scale-95">
                        <img
                            src="/service4.jpg"
                            alt="Card 2"
                            className="w-full h-60 rounded-md mb-4"
                        />
                        <h3 className="text-xl font-semibold text-blue-950">{t('services.advertising.title')}</h3>
                        <p className="text-gray-700 text-center">{t('services.advertising.description')}</p>
                    </div>
                </div>


                <div className="flex flex-col items-center justify-center w-full md:w-1/3 p-4">
                    <div
                        className="bg-white shadow-md rounded-lg p-2 flex flex-col items-center h-96 transition-transform duration-500 hover:scale-95">
                        <img
                            src="/service5.jpg"
                            alt="Card 3"
                            className="w-full h-60 object-cover rounded-md mb-4"
                        />
                        <h3 className="text-xl font-semibold text-blue-950">{t('services.graphicDesign.title')}</h3>
                        <p className="text-gray-700 text-center">{t('services.graphicDesign.description')}</p>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center w-full md:w-1/3 p-4">
                    <div
                        className="bg-white shadow-md rounded-lg p-2 flex flex-col items-center h-96 transition-transform duration-500 hover:scale-95">
                        <img
                            src="/service6.jpg"
                            alt="Card 3"
                            className="w-full h-60 object-cover rounded-md mb-4"
                        />
                        <h3 className="text-xl font-semibold text-blue-950">{t('services.consultation.title')}</h3>
                        <p className="text-gray-700 text-center">{t('services.consultation.description')}</p>
                    </div>
                </div>
            </div>


            <div
                className="relative min-h-screen flex flex-col w-full max-w-8xl  bg-gray-100 shadow-lg rounded-lg mt-28">
                <video
                    className=""
                    controls
                    src="/service7.mp4"
                >
                    Your browser does not support the video tag.
                </video>
                <div className="absolute left-0 right-0 mt-96 p-20  text-white text-center">
                    <p className="text-6xl">
                        {t('services.video')}
                    </p>
                </div>
            </div>


            <div className="w-full flex flex-col items-center justify-center p-12 mb-20 mt-20 bg-gray-200 text-center">
                <h2 className="text-3xl font-semibold mb-4">{t('services.howCanWeHelp')}</h2>
                <p className="text-lg mb-6">
                    {t('services.readyToInnovate')}
                </p>
                <button onClick={handleButtonClick}
                        className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition">
                    {t('services.buttonText')}
                </button>
            </div>
        </div>
    );
};

export default Services;
