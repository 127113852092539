import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";


const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer dir={i18n.dir()} className="bg-[#1E314B] text-white p-4 ">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 mt-8 gap-4">

                <div className="text-left  text-white md:text-left">
                    <h2 className="font-bold text-3xl">{t('footer.title')}</h2>
                    <p className="mt-4 font-normal text-1xl">{t('footer.description')}</p>
                </div>

                <div className="text-left text-white md:text-left">
                    <h3 className="font-semibold text-2xl">{t('footer.services.title')}</h3>
                    <ul className="mt-4 gap-2 text-sm">
                        {t('footer.services.list', { returnObjects: true }).map((service, index) => (
                            <li key={index}>{service}</li>
                        ))}
                    </ul>
                </div>

                <div className="text-left text-white md:text-left">
                    <h3 className="font-semibold text-2xl">{t('footer.followUs.title')}</h3>
                    <ul className="mt-4 gap-2 text-sm">
                        <li><a href="https://www.facebook.com/profile.php?id=61566734673930"
                               className="hover:underline flex">  <FaFacebookSquare className="ml-2 mr-2 size-4"/> {t('footer.followUs.list.facebook')}
                        </a></li>
                        <li><a href="https://x.com/xposio1" className="hover:underline flex"> <FaTwitterSquare
                            className="ml-2 mr-2 size-4"/> {t('footer.followUs.list.twitter')}  </a></li>
                        <li><a href="https://www.instagram.com/xposio1/"
                               className="hover:underline flex"> <FaInstagram className="ml-2 mr-2 size-4"/> {t('footer.followUs.list.instagram')}  </a>
                        </li>
                        <li><a href="https://x.com/xposio1" className="hover:underline flex "> <FaLinkedin
                            className="ml-2 mr-2 size-4"/> {t('footer.followUs.list.linkedin')}  </a></li>
                    </ul>
                </div>

                <div className="text-left text-white md:text-left">
                    <h3 className="font-semibold text-2xl">{t('footer.contactUs.title')}</h3>
                    <ul className="mt-4 text-sm">
                        {t('footer.contactUs.list', {returnObjects: true}).map((contact, index) => (
                            <li key={index}>{contact}</li>
                        ))}
                    </ul>
                </div>
            </div>


            <div className="text-white text-center mt-24">
                <p>{t('footer.copyright')}</p>
            </div>
        </footer>
    );
};
export default Footer;