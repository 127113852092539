import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import i18n from "../i18n";

const Portfolio = () => {
    const {t}= useTranslation();
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/contact-us');
    };

    return (
        <div dir={i18n.dir()}  className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <h1 className="text-6xl text-blue-950 mt-20 font-semibold mb-12">{t('portfolio.title')}</h1>


            <div className="flex flex-col md:flex-row items-center justify-between w-4/5 mb-10">
                <div className="w-full md:w-1/2">
                    <img
                        src="/portfolio.jpeg"
                        alt="Digital Marketing Portfolio Image"
                        className="w-[80%] px-2 h-[500px] object-cover transition-transform duration-500 hover:scale-105"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pl-8 mb-24 md:mt-0 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-5xl text-blue-950 font-bold mb-4">{t('portfolio.webDevelopment.header')}</h2>
                    <p className="text-3xl pr-4 text-gray-700">
                        {t('portfolio.webDevelopment.description')}
                    </p>
                </div>
            </div>


            <div className="flex flex-col md:flex-row-reverse mb-16 items-center justify-between w-4/5">
                <div className="w-full  md:w-1/2">
                    <img
                        src="/portfolio1.webp"
                        alt="Technology Portfolio Image"
                        className="w-[90%] pl-6 h-[500px] object-cover transition-transform duration-500 hover:scale-95"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pr-8 mt-8 md:mt-0 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-5xl pl-4 text-blue-950  font-bold mb-4">{t('portfolio.mobileAppDevelopment.header')} </h2>
                    <p className="text-3xl pl-4 text-gray-700">
                        {t('portfolio.mobileAppDevelopment.description')}
                    </p>
                </div>
            </div>


            <div className="flex flex-col md:flex-row items-center justify-between w-4/5 mb-10">
                <div className="w-full md:w-1/2">
                    <img
                        src="/image7.webp"
                        alt="Digital Marketing Portfolio Image"
                        className="w-[80%] px-2 h-[500px] object-cover transition-transform duration-500 hover:scale-105"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pl-8 mb-24 md:mt-0 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-5xl text-blue-950 font-bold mb-4"> {t('portfolio.digitalMarketing.header')}</h2>
                    <p className="text-3xl pr-4 text-gray-700">
                        {t('portfolio.digitalMarketing.description')}
                    </p>
                </div>
            </div>


            <div className="flex flex-col md:flex-row-reverse mb-16 items-center justify-between w-4/5">
                <div className="w-full  md:w-1/2">
                    <img
                        src="/image8.jpg"
                        alt="Technology Portfolio Image"
                        className="w-[90%] pl-6 h-[500px] object-cover transition-transform duration-500 hover:scale-95"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pr-8 mt-8 md:mt-0 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-5xl pl-4 text-blue-950  font-bold mb-4">{t('portfolio.graphicDesign.header')}</h2>
                    <p className="text-3xl pl-4 text-gray-700">
                        {t('portfolio.graphicDesign.description')}
                    </p>
                </div>
            </div>


            <div className="flex flex-col md:flex-row items-center justify-between w-4/5 mb-10">
                <div className="w-full md:w-1/2">
                    <img
                        src="/portfolio4.jpeg"
                        alt="Digital Marketing Portfolio Image"
                        className="w-[80%] px-2 h-[500px] object-cover transition-transform duration-500 hover:scale-105"
                    />
                </div>
                <div className="w-full md:w-1/2 md:pl-8 mb-24 md:mt-0 transition-transform duration-500 hover:scale-95">
                    <h2 className="text-5xl text-blue-950 font-bold mb-4">{t('portfolio.advertising.header')}</h2>
                    <p className="text-3xl pr-4 text-gray-700">
                        {t('portfolio.advertising.description')}
                    </p>
                </div>
            </div>


            <div className="w-full flex flex-col items-center justify-center p-12 mb-12  bg-gray-200 text-center">
                <h2 className="text-3xl font-semibold mb-4">{t('portfolio.howCanWeHelp')}</h2>
                <p className="text-lg mb-6">
                    {t('portfolio.readyToInnovate')}
                </p>
                <button onClick={handleButtonClick}
                        className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition">
                    {t('portfolio.callToAction')}
                </button>
            </div>

        </div>
    );
};

export default Portfolio;
