import React from 'react';

const CustomButton = ({ text, onClick, theme = 'blue', size = 'medium', type = 'button', disabled = false }) => {
    const baseStyle = 'rounded px-4 py-2 font-semibold focus:outline-none';

    const themeClasses = {
        blue: 'bg-blue-600 text-white hover:bg-blue-700',
        red: 'bg-red-600 text-white hover:bg-red-700',
        green: 'bg-green-600 text-white hover:bg-green-700',
        gray: 'bg-gray-600 text-white hover:bg-gray-700',
    };

    const sizeClasses = {
        small: 'text-xs px-2 py-1',
        medium: 'text-sm px-4 py-2',
        large: 'text-lg px-6 py-3',
    };

    return (
        <button
            type={type}
            className={`${baseStyle} ${themeClasses[theme]} ${sizeClasses[size]}`}
            onClick={onClick}
            disabled={disabled}
        >
            {text}
        </button>
    );
};

export default CustomButton;
